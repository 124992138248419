<template>
  <b-sidebar
    id="opened-add-modal-form"
    :visible="openedAddModalForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-add-modal-form', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('general.add') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <!-- Form -->
      <b-form class="p-2">

        <!-- Group -->
        <b-form-group
          :label="$t('form.code.label')"
          label-for="group"
        >
          <b-form-input
            id="group"
            v-model="item.group"
            :state="errors && errors.group ? false : null"
          />

          <b-form-invalid-feedback v-if="errors && errors.group">
            {{ errors.group[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Key -->
        <b-form-group
          :label="$t('form.key.label')"
          label-for="key"
        >
          <b-form-input
            id="key"
            v-model="item.key"
            :state="errors && errors.key ? false : null"
          />

          <b-form-invalid-feedback v-if="errors && errors.key">
            {{ errors.key[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Text -->
        <b-form-group
          v-for="(value, locale) in item.text"
          :key="locale"
          :label="locale"
          :label-for="'text.' + locale"
        >
          <b-form-textarea
            :id="locale"
            v-model="item.text[locale]"
            :state="errors && errors['text.' + locale] ? false : null"
          />

          <b-form-invalid-feedback v-if="errors && errors['text.' + locale]">
            {{ errors['text.' + locale][0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'openedAddModalForm',
    event: 'update:opened-add-modal-form',
  },
  props: {
    openedAddModalForm: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      item: {
        group: null,
        key: null,
        text: {
          ru: null,
          uk: null,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-add-modal-form', false)
    },
    onSubmit() {
      this.$http.post('/api/translations/', {
        group: this.item.group,
        key: this.item.key,
        text: this.item.text,
      })
        .then(() => {
          this.$emit('refetch-data')
          this.$emit('update:opened-add-modal-form', false)
          this.$store.dispatch('validation/clearErrors')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-add-modal-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
