<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('admin.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('admin.table.fields.group') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="groupFilter"
            :options="groupOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:groupFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    groupFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      groupOptions: [],
    }
  },
  beforeCreate() {
    this.$store
      .dispatch('translations/groups', {})
      .then(response => {
        const { data } = response.data

        this.groupOptions = data
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
